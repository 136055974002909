.vert-move {
    /* animation: mover 1s infinite alternate; */
    -webkit-animation: mover 2s infinite alternate;
    animation: mover 2s infinite alternate;
    width: 310px;
    height: 5px;
    background-color: white;
    border-radius: 5;
    /* animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: mover;
    animation-timing-function: linear; */
    box-shadow: 0px 40px 15px cyan;
}

/* hr.vert-move {
    -webkit-animation: mover 2s infinite alternate;
    animation: mover 2s infinite alternate;
} */

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
        width: 10px;
    }

    100% {
        transform: translateY(0px);
        width: 10px;

    }
}

@keyframes mover {
    0% {
        width: 10px;
        transform: translateY(0);
    }

    30% {
        width: 300px;
        transform: translateY(0);
    }

    100% {
        width: 300px;
        transform: translateY(290px);
    }
}


div.smart_camera_slider {
    width: 100%;
}

div.smart_camera_slider .slick-dots {
    bottom: 40px;
}

div.smart_camera_slider .slick-dots .slick-active {
    background-color: #94c100;
    color: #94c100;
    width: 35px;
    height: 15px;
    margin-top: 15px;
    border: 2px solid white;
    border-radius: 10px;
    box-shadow: 0px 2px 6px lightgray;

}

div.smart_camera_slider li {

    border: 2px solid white;
    border-radius: 10px;
    color: aliceblue;
    box-shadow: 0px 2px 6px lightgray;

}

div.smart_camera_slider .slick-dots li.slick-active button:before {
    display: none;
}

div.smart_camera_slider .slick-dots li button:before {
    color: aliceblue;
}