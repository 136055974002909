@font-face {
    font-family: "Lato-Regular";
    /*Can be any text*/
    src: local("Lato-Regular"),
        url("../src/assets/fonts/Lato-Regular.ttf") format("truetype");
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {

    z-index: 110000 !important;
}

.swal2-container.swal2-top,
.swal2-container.swal2-center,
.swal2-container.swal2-bottom {
    z-index: 110001 !important;
}