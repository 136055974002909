.container {
    height: 100vh;
    width: 350px;
    overflow: hidden !important;
    align-self: center;
    margin: auto;
}






@media screen and (max-width:600px) {
    .container {
        height: 100%;
        width: 100%;

    }
}